import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { bindActionCreators } from "redux";
import getCookie from "../../functions/cookies";
import { actionCreators } from "../../state";
import { useNavigate, useLocation } from "react-router-dom";

function Menu() {
  const user = useSelector((state) => state.user);
  const project = useSelector((state) => state.project);

  const dispatch = useDispatch();
  const { setProject, setOverlay } = bindActionCreators(
    actionCreators,
    dispatch
  );
  const history = useNavigate();

  const location = useLocation();
  const mainPath = location.pathname.split("/")[1].replace("/", "");

  console.log("Location =>", mainPath);
  useEffect(() => {
    if (user) {
      console.log("Projects =>", user, user.projects);

      if (user.projects.length === 1 && project === null) {
        console.log("Setting default project");
        setProject(user.projects[0]);
      }
      if (getCookie("project") && user.projects.length && project === null) {
        const p = user.projects.find((p) => p.id === getCookie("project"));
        if (p) {
          console.log("Setting project with cookie");
          setProject(p);
        }
      }
    }
  }, [user, project, setProject]);

  return (
    <Navbar expand="lg" sticky="top" style={{ backgroundColor: "#212529" }}>
      <Container>
        <Navbar.Brand href="/" className="">
          <img
            alt=""
            src="/skin-white-logo.png"
            width="70"
            height="auto"
            className="align-center"
            style={logoStyle}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {user &&
          user.authorisations &&
          user.authorisations.loop &&
          mainPath === "clickup" ? (
            <Nav className="me-auto">
              <LinkContainer to="/clickup">
                <Nav.Link>Team Overview</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/clickup/budget">
                <Nav.Link>Budget</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/clickup/capacity">
                <Nav.Link>Capacity</Nav.Link>
              </LinkContainer>
            </Nav>
          ) : null}
          {user &&
          user.authorisations &&
          user.authorisations.loop &&
          mainPath !== "clickup" ? (
            <Nav className="me-auto">
              <LinkContainer to="/dashboard">
                <Nav.Link>Dashboard</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/journeys">
                <Nav.Link>Journeys</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/ux-potential">
                <Nav.Link>UX</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/content">
                <Nav.Link>Content</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/stock">
                <Nav.Link>Stock</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/checkout">
                <Nav.Link>Checkout</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/systems">
                <Nav.Link>Systems</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/e2e">
                <Nav.Link>E2E</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/errors">
                <Nav.Link>Errors</Nav.Link>
              </LinkContainer>
              {/* <Nav.Link>Contexts</Nav.Link> */}

              {/* <Nav.Link>UX Standards</Nav.Link>
          <Nav.Link>Funnels</Nav.Link> */}
            </Nav>
          ) : null}
          {user ? (
            <Nav id="main-nav">
              {/* <LinkContainer
                to="#"
                onClick={() => setOverlay({ active: true, type: "annotation" })}
              >
                <Nav.Link>📝</Nav.Link>
              </LinkContainer> */}
              {user.authorisations &&
              user.authorisations.loop &&
              mainPath !== "clickup" ? (
                <Nav>
                  <LinkContainer to="/clickup">
                    <Nav.Link>Loop 🔹</Nav.Link>
                  </LinkContainer>
                  <NavDropdown
                    drop="start"
                    title={project ? project.name : "Select project"}
                    id="project-selection-dropdown"
                    variant="primary"
                  >
                    {user.projects &&
                      user.projects.map((p) => (
                        <NavDropdown.Item
                          key={p.id}
                          onClick={() => {
                            setProject(p);
                            document.cookie = "project=" + p.id;
                            // history.push("/dashboard");
                            history(`/dashboard`);
                          }}
                        >
                          {p.name}
                        </NavDropdown.Item>
                      ))}
                  </NavDropdown>
                </Nav>
              ) : null}
              {user.authorisations &&
              user.authorisations.loop &&
              mainPath === "clickup" ? (
                <LinkContainer to="/dashboard">
                  <Nav.Link>Skin+</Nav.Link>
                </LinkContainer>
              ) : null}
            </Nav>
          ) : null}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

const logoStyle = {
  marginBottom: "5px",
};

export default Menu;
