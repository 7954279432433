//@ts-check
import React from "react";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function Projects() {
  const user = useSelector((state) => state.user);
  const time = useSelector((state) => state.time);
  const annotations = useSelector((state) => state.annotations);

  useEffect(() => {
    console.log("Annotations list for project ");
  }, [annotations]);

  const isProjectVisible = (p) => {
    if (p.env === "production") {
      return true;
    }
    return false;
  };

  const projectName = (p) => {
    return p.name.replace("www.", "") + " [" + p.env.toUpperCase() + "]";
  };
  return (
    <Container style={containerStyle}>
      {!user ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>Projects overview</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time &&
            time.timeSelected &&
            user.projects &&
            user.projects
              .filter((p) => isProjectVisible(p))
              .map((p) => (
                <Row>
                  <Col md={7} xs={6}>
                    <Title>{projectName(p)}</Title>
                    {time.timeSelected === time.availableWeeks[0] ? (
                      <DataContainer
                        apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${p.id}/dashboard-status/${time.timeSelected}/daily/today`}
                        type="cards"
                        project={p}
                      />
                    ) : null}
                    {p.adminURL ? (
                      <Button size="sm" href={p.adminURL}>
                        🎨 Admin
                      </Button>
                    ) : null}
                  </Col>
                  <Col md={5} xs={6}>
                    <Title>Speed</Title>
                    <DataContainer
                      apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${p.id}/speed/${time.timeSelected}`}
                      type="cards"
                    />
                  </Col>
                </Row>
              ))}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

const titles = {};

export default Projects;
