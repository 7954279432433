import axios from "axios";

const PARSE_SERVER_URL = process.env.REACT_APP_PARSE_SERVER_URL;
const PARSE_REST_KEY = process.env.REACT_APP_PARSE_REST_API_KEY;
const PARSE_APP_ID = process.env.REACT_APP_PARSE_APP_ID;

// Get objects from Parse

export const getParseObj = async (parseClass, objectId) => {
  const url = `${PARSE_SERVER_URL}/parse/classes/${parseClass}/${objectId}`;
  const headers = {
    "X-Parse-Application-Id": PARSE_APP_ID,
    "X-Parse-REST-API-Key": PARSE_REST_KEY,
  };
  return axios.get(url, { headers });
};

export const parseQuery = async (parseClass, query) => {
  const url = `${PARSE_SERVER_URL}/parse/classes/${parseClass}`;
  const headers = {
    "X-Parse-Application-Id": PARSE_APP_ID,
    "X-Parse-REST-API-Key": PARSE_REST_KEY,
  };
  const data = await axios.get(url, { headers, params: query });
  return data.data.results;
};
export const parseQueryFirst = async (parseClass, query) => {
  const url = `${PARSE_SERVER_URL}/parse/classes/${parseClass}`;
  const headers = {
    "X-Parse-Application-Id": PARSE_APP_ID,
    "X-Parse-REST-API-Key": PARSE_REST_KEY,
  };
  const data = await axios.get(url, { headers, params: query });
  if (data.data.results.length === 1) {
    return data.data.results[0];
  } else {
    return null;
  }
};

// Create objects in Parse

export const createParseObj = async (parseClass, data) => {
  const url = `${PARSE_SERVER_URL}/parse/classes/${parseClass}`;
  const headers = {
    "X-Parse-Application-Id": PARSE_APP_ID,
    "X-Parse-REST-API-Key": PARSE_REST_KEY,
  };
  console.log("headers =>", headers);
  return axios.post(url, data, { headers });
};

// Update objects in Parse

export const updateParseObj = async (parseClass, objectId, data) => {
  const url = `${PARSE_SERVER_URL}/parse/classes/${parseClass}/${objectId}`;
  const headers = {
    "X-Parse-Application-Id": PARSE_APP_ID,
    "X-Parse-REST-API-Key": PARSE_REST_KEY,
  };
  return axios.put(url, data, { headers });
};

// Delete objects in Parse

export const deleteParseObj = async (parseClass, objectId) => {
  const url = `${PARSE_SERVER_URL}/parse/classes/${parseClass}/${objectId}`;
  const headers = {
    "X-Parse-Application-Id": PARSE_APP_ID,
    "X-Parse-REST-API-Key": PARSE_REST_KEY,
  };
  return axios.delete(url, { headers });
};
