//@ts-check
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "./state/index";
import getCookie from "./functions/cookies";
import Container from "react-bootstrap/Container";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import Menu from "./components/nav/menu";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/home";
import Checkout from "./pages/checkout";
import Journeys from "./pages/journeys";
import SessionPage from "./pages/sessionPage";
import UXPotential from "./pages/ux-potential";
import Projects from "./pages/projects";
import WelcomeBack from "./components/welcome-back";
import Errors from "./pages/errors";
import Stock from "./pages/stock";
import Systems from "./pages/systems";
import Content from "./pages/content";
import E2E from "./pages/e2e";
import ModalsAndOverlays from "./components/modal-overalays";
import ClickUpHome from "./pages/clickup/clickupHome";
import ClickupUser from "./pages/clickup/clickupUser";
import ClickUpCR from "./pages/clickup/clickupCR";
import ClickupCustomer from "./pages/clickup/clickupCustomer";
import { POContextProvider } from "./context/ParseObject";
import ClickupCapacity from "./pages/clickup/clickupCapacity";
import ClickUpTeam from "./pages/clickup/clickupTeam";
import SystemEvent from "./pages/systems-single-event";

function App() {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { loginUser } = bindActionCreators(actionCreators, dispatch);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!user) {
      const userCookie = getCookie("logged");
      console.log("cookie =>", userCookie);

      if (userCookie) {
        loginUser(userCookie);
      } else {
        setLoading(false);
      }
    }
    console.log("user =>", user);
  }, [user, loginUser]);

  return (
    <Router>
      {!user ? (
        <WelcomeBack loading={loading} />
      ) : (
        <Container fluid>
          <Menu />
          <ModalsAndOverlays />
          <POContextProvider>
            <Routes>
              <Route path="/" exact element={<Projects />} />
              <Route path="/clickup" exact element={<ClickUpHome />} />
              <Route path="/clickup/budget" exact element={<ClickUpCR />} />
              <Route
                path="/clickup/capacity"
                exact
                element={<ClickupCapacity />}
              />
              <Route
                path="/clickup/team/:teamId"
                exact
                element={<ClickUpTeam />}
              />

              <Route
                path="/clickup/customer/:customerId"
                exact
                element={<ClickupCustomer />}
              />
              <Route
                path="/clickup/user/:userId"
                exact
                element={<ClickupUser />}
              />
              <Route path="/dashboard" exact element={<Home />} />
              <Route path="/journeys/:sessionId" element={<SessionPage />} />
              <Route path="/journeys" exact element={<Journeys />} />
              <Route path="/ux-potential" exact element={<UXPotential />} />
              <Route path="/content" exact element={<Content />} />
              <Route path="/stock" exact element={<Stock />} />
              <Route path="/checkout" exact element={<Checkout />} />
              <Route path="/errors" exact element={<Errors />} />
              <Route
                path="/systems/event/:eventId"
                exact
                element={<SystemEvent />}
              />
              <Route path="/systems" exact element={<Systems />} />
              <Route path="/e2e" exact element={<E2E />} />
            </Routes>
          </POContextProvider>
        </Container>
      )}
    </Router>
  );
}

export default App;
