const reducer = (state = [], action) => {
  switch (action.type) {
    case "setAnnotations":
      console.log("setAnnotations =>", action.payload);
      return action.payload;
    default:
      return state;
  }
};
export default reducer;
