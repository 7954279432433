//@ts-check
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function Content() {
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);
  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col>
                <Title>Business KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/business/${time.timeSelected}`}
                  type="cards"
                />

                <Title>Landing Types</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/types/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>Top 50 Landing Focus</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/${project.id}/table/${time.timeSelected}?limit=50&pick=first&sortParam=percFirst`}
                  type="table"
                />
                <Title>Top 50 Traffic Page Focus</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/${project.id}/table/${time.timeSelected}?limit=50`}
                  type="table"
                />
              </Col>
              <Col>
                <Title>Store potential KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/store-potential/${time.timeSelected}`}
                  type="cards"
                />
                <Title>🚨 404 Errors</Title>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/client-error/${project.id}/table/${time.timeSelected}/404/all`}
                  type="table"
                />
                <Title>Top 50 Product by Traffic</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/${project.id}/table/${time.timeSelected}?limit=50&type=product`}
                  type="table"
                />

                <Title>Top 50 Listings by Traffic</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/${project.id}/table/${time.timeSelected}?limit=50&type=listing`}
                  type="table"
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

export default Content;
