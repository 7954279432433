import { parseQuery } from "../../functions/parse";

export const loginUser = (userId) => {
  return async (dispatch) => {
    // fetch user
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/me/${userId}`
      );
      const me = await response.json();
      console.log("me =>", me);
      dispatch({
        type: "loginUser",
        payload: { id: userId, ...me },
      });
      dispatch({
        type: "fetchAnnotations",
        payload: { id: userId, ...me },
      });
    } catch (error) {
      console.log("Error get me =>", error);
      dispatch({
        type: "logoutUser",
        payload: null,
      });
    }
  };
};
export const fetchAnnotations = (user) => {
  return async (dispatch) => {
    const query = {
      where: {
        project: { $in: user.projects.map((project) => project.id) },
      },
    };
    console.log("query =>", query);
    try {
      const data = await parseQuery("Annotation", query);
      console.log("data =>", data);
      dispatch({
        type: "setAnnotations",
        payload: data,
      });
    } catch (error) {
      console.log("fetchAnnotations error =>", error);
      dispatch({
        type: "setAnnotations",
        payload: [],
      });
    }
  };
};
export const setProject = (project) => {
  return (dispatch) => {
    console.log("Set Project =>", project);
    dispatch({
      type: "setProject",
      payload: { ...project },
    });
  };
};
export const setTime = (time) => {
  return (dispatch) => {
    console.log("Set Time =>", time);
    dispatch({
      type: "setTime",
      payload: { ...time },
    });
  };
};
export const setModal = (modal) => {
  return (dispatch) => {
    console.log("Set Modal =>", modal);
    dispatch({
      type: "setModal",
      payload: { ...modal },
    });
  };
};
export const setOverlay = (overlay) => {
  return (dispatch) => {
    console.log("Set Overlay =>", overlay);
    dispatch({
      type: "setOverlay",
      payload: { ...overlay },
    });
  };
};
export const logoutUser = () => {
  return (dispatch) => {
    dispatch({
      type: "logoutUser",
      payload: null,
    });
  };
};
