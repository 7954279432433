//@ts-check
import React from "react";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";
import qs from "qs";

function Journeys(props) {
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);
  const [funnelStep, setFunnelStep] = useState(null);
  const [userFilter, setUserFilter] = useState(null);
  const [deviceFilter, setDeviceFilter] = useState(null);
  const [checkoutError, setCheckoutError] = useState(null);
  const [clientError, setClientError] = useState(null);
  const [checkoutWarning, setCheckoutWarning] = useState(null);
  const [checkoutStep, setCheckoutStep] = useState(null);
  const [context, setContext] = useState(null);
  const [contextMapping, setContextMapping] = useState([]);
  const history = useNavigate();
  const location = useLocation();

  console.log("props =>", props);

  const funnelStepMapping = [
    { name: "All", filter: null },
    { name: "Visitor", filter: "0" },
    { name: "Discovery", filter: "1" },
    { name: "Engage", filter: "2" },
    { name: "Shopping", filter: "3" },
    { name: "Customer", filter: "4" },
  ];
  const checkoutStepMapping = [
    { name: "All", filter: null },
    { name: "Start", filter: "0" },
    { name: "Click Address", filter: "1" },
    { name: "Click Shipping", filter: "2" },
    { name: "Click Pay", filter: "3" },
    { name: "Purchase", filter: "4" },
  ];

  useEffect(() => {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    console.log("Query =>", query);
    if (query.funnelStep) {
      setFunnelStep(query.funnelStep);
    }
    if (query.checkoutStep) {
      setCheckoutStep(query.checkoutStep);
    }
    if (query.context) {
      setContext(query.context);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchContexts = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/context/${project.id}/list`
      );
      // console.log(response)
      const contextData = await response.json();
      setContextMapping(contextData.data);
      // console.log('contexMapping =>', contextMapping.data)
    };
    if (project && project.id) {
      fetchContexts();
    }
  }, [project]);

  useEffect(() => {
    const params = new URLSearchParams();
    let q = "";
    if (funnelStep) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `funnelStep=${funnelStep}`;
      params.append("funnelStep", funnelStep);
    } else {
      params.delete("funnelStep");
    }
    if (checkoutStep) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `checkoutClickStep=${checkoutStep}`;
      params.append("checkoutClickStep", checkoutStep);
    } else {
      params.delete("checkoutClickStep");
    }
    if (context) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `context=${context}`;
      params.append("context", context);
    } else {
      params.delete("context");
    }
    if (checkoutError) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `checkoutError=${checkoutError}`;
      params.append("checkoutError", checkoutError);
    } else {
      params.delete("checkoutError");
    }
    if (clientError) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `clientError=${clientError}`;
      params.append("clientError", clientError);
    } else {
      params.delete("clientError");
    }
    if (checkoutWarning) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `checkoutWarning=${checkoutWarning}`;
      params.append("checkoutWarning", checkoutWarning);
    } else {
      params.delete("checkoutWarning");
    }
    if (userFilter) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `user=${userFilter}`;
      params.append("user", userFilter);
    } else {
      params.delete("user");
    }
    if (deviceFilter) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `device=${deviceFilter}`;
      params.append("device", deviceFilter);
    } else {
      params.delete("device");
    }
    // history.push({ search: params.toString() });
    history(`${params.toString()}`);
  }, [
    history,
    context,
    checkoutStep,
    funnelStep,
    checkoutError,
    checkoutWarning,
    userFilter,
    deviceFilter,
    clientError,
  ]);

  const queryBuilder = () => {
    let q = "";
    if (funnelStep) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `funnelStep=${funnelStep}`;
    }
    if (checkoutStep) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `checkoutClickStep=${checkoutStep}`;
    }
    if (context) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `context=${context}`;
    }
    if (checkoutError) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `checkoutError=${checkoutError}`;
    }
    if (checkoutWarning) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `checkoutWarning=${checkoutWarning}`;
    }
    if (userFilter) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `user=${userFilter}`;
    }
    if (deviceFilter) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `device=${deviceFilter}`;
    }
    if (clientError) {
      if (q === "") {
        q += "?";
      } else {
        q += "&";
      }
      q += `clientError=${clientError}`;
    }
    return q;
  };

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col>
                <Title>Customer Journeys</Title>
                <DataContainer
                  apiUrl={`${
                    process.env.REACT_APP_DATALAYER_SERVER_URL
                  }/api/journeys/${project.id}/${
                    time.timeSelected
                  }${queryBuilder()}`}
                  type="sessions"
                  colors={true}
                />
              </Col>
              <Col md={{ span: 2, offset: 2 }}>
                <Title>Filters</Title>

                <Dropdown.Menu show style={filterStyle}>
                  <Dropdown.Header>Checkout Error</Dropdown.Header>
                  <Dropdown.Item
                    active={checkoutError === null}
                    eventKey={`fs-checkoutError-0`}
                    key={`fs-checkoutError-0`}
                    onClick={() => setCheckoutError(null)}
                  >
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={checkoutError === true}
                    eventKey={`fs-checkoutError-1`}
                    key={`fs-checkoutError-1`}
                    onClick={() => setCheckoutError(true)}
                  >
                    With error
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>Client Error</Dropdown.Header>
                  <Dropdown.Item
                    active={clientError === null}
                    eventKey={`fs-clientError-0`}
                    key={`fs-clientError-0`}
                    onClick={() => setClientError(null)}
                  >
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={clientError === true}
                    eventKey={`fs-clientError-1`}
                    key={`fs-clientError-1`}
                    onClick={() => setClientError(true)}
                  >
                    With error
                  </Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header>Checkout Warning</Dropdown.Header>
                  <Dropdown.Item
                    active={checkoutWarning === null}
                    eventKey={`fs-checkoutWarning-0`}
                    key={`fs-checkoutWarning-0`}
                    onClick={() => setCheckoutWarning(null)}
                  >
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={checkoutWarning === true}
                    eventKey={`fs-checkoutWarning-1`}
                    key={`fs-checkoutWarning-1`}
                    onClick={() => setCheckoutWarning(true)}
                  >
                    With warning
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Header>Device</Dropdown.Header>
                  <Dropdown.Item
                    active={deviceFilter === null}
                    eventKey={`fs-deviceFilter-0`}
                    key={`fs-deviceFilter-0`}
                    onClick={() => setDeviceFilter(null)}
                  >
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={deviceFilter === "desktop"}
                    eventKey={`fs-deviceFilter-1`}
                    key={`fs-deviceFilter-1`}
                    onClick={() => setDeviceFilter("desktop")}
                  >
                    Desktop
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={deviceFilter === "mobile"}
                    eventKey={`fs-deviceFilter-2`}
                    key={`fs-deviceFilter-2`}
                    onClick={() => setDeviceFilter("mobile")}
                  >
                    Mobile
                  </Dropdown.Item>

                  <Dropdown.Divider />
                  <Dropdown.Header>User status</Dropdown.Header>
                  <Dropdown.Item
                    active={userFilter === null}
                    eventKey={`fs-userFilter-0`}
                    key={`fs-userFilter-0`}
                    onClick={() => setUserFilter(null)}
                  >
                    All
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={userFilter === "not_logged"}
                    eventKey={`fs-userFilter-1`}
                    key={`fs-userFilter-1`}
                    onClick={() => setUserFilter("not_logged")}
                  >
                    Guest
                  </Dropdown.Item>
                  <Dropdown.Item
                    active={userFilter === "logged"}
                    eventKey={`fs-userFilter-2`}
                    key={`fs-userFilter-2`}
                    onClick={() => setUserFilter("logged")}
                  >
                    User
                  </Dropdown.Item>

                  {/* <Dropdown.Divider /> */}
                </Dropdown.Menu>
              </Col>
              <Col md={{ span: 2, offset: 0 }}>
                <Title>Filters </Title>
                <Dropdown.Menu show style={filterStyle}>
                  <Dropdown.Header>Funnel Step</Dropdown.Header>
                  {funnelStepMapping.map((s) => {
                    return (
                      <Dropdown.Item
                        active={funnelStep === s.filter}
                        eventKey={`fs-${s.filter}`}
                        key={`fs-${s.filter}`}
                        onClick={() => setFunnelStep(s.filter)}
                      >
                        {s.name}
                      </Dropdown.Item>
                    );
                  })}
                  <Dropdown.Divider />
                  <Dropdown.Header>Checkout Step</Dropdown.Header>
                  {checkoutStepMapping.map((s) => {
                    return (
                      <Dropdown.Item
                        active={checkoutStep === s.filter}
                        eventKey={`cs-${s.filter}`}
                        key={`cs-${s.filter}`}
                        onClick={() => setCheckoutStep(s.filter)}
                      >
                        {s.name}
                      </Dropdown.Item>
                    );
                  })}
                  <Dropdown.Divider />
                  <Dropdown.Header>Contexts</Dropdown.Header>
                  <Dropdown.Item
                    active={context === null}
                    eventKey={`c-${null}`}
                    key={`c-${null}`}
                    onClick={() => setContext(null)}
                  >
                    All
                  </Dropdown.Item>
                  {contextMapping
                    ? contextMapping.map((s) => {
                        return (
                          <Dropdown.Item
                            active={context === s}
                            eventKey={`c-${s}`}
                            key={`c-${s}`}
                            onClick={() => setContext(s)}
                          >
                            {s}
                          </Dropdown.Item>
                        );
                      })
                    : null}
                </Dropdown.Menu>
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};
const filterStyle = {
  marginTop: "25px",
};

export default Journeys;
