//@ts-check
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import ListGroup from "react-bootstrap/ListGroup";
import { v4 as uuid } from "uuid";
import Cards from "../cards";
import Gallery from "../imageGallery";
import DataTable from "../table";
import { Title } from "../typography";

/**
 * @typedef {object} Test
 * @property {string} id
 * @property {object} results
 * @property {string} results.startedTestsAt
 * @property {string} results.endedTestsAt
 * @property {string} results.status
 * @property {number} results.totalDuration
 * @property {number} results.totalSuites
 * @property {number} results.totalTests
 * @property {number} results.totalFailed
 * @property {number} results.totalPassed
 * @property {number} results.totalPending
 * @property {number} results.totalSkipped
 * @property {object[]} results.runs
 * @property {null} results.runs.error
 * @property {string} results.runs.reporter
 * @property {object} results.runs.reporterStats
 * @property {number} results.runs.reporterStats.suites
 * @property {number} results.runs.reporterStats.tests
 * @property {number} results.runs.reporterStats.passes
 * @property {number} results.runs.reporterStats.pending
 * @property {number} results.runs.reporterStats.failures
 * @property {string} results.runs.reporterStats.start
 * @property {string} results.runs.reporterStats.end
 * @property {number} results.runs.reporterStats.duration
 * @property {any} results.runs.screenshots
 * @property {object} results.runs.spec
 * @property {string} results.runs.spec.absolute
 * @property {string} results.runs.spec.fileExtension
 * @property {string} results.runs.spec.fileName
 * @property {string} results.runs.spec.name
 * @property {string} results.runs.spec.relative
 * @property {object} results.runs.stats
 * @property {number} results.runs.stats.duration
 * @property {string} results.runs.stats.endedAt
 * @property {number} results.runs.stats.failures
 * @property {number} results.runs.stats.passes
 * @property {number} results.runs.stats.pending
 * @property {number} results.runs.stats.skipped
 * @property {string} results.runs.stats.startedAt
 * @property {number} results.runs.stats.suites
 * @property {number} results.runs.stats.tests
 * @property {object[]} results.runs.tests
 * @property {object[]} results.runs.tests.attempts
 * @property {string} results.runs.tests.attempts.state
 * @property {null} results.runs.tests.displayError
 * @property {number} results.runs.tests.duration
 * @property {string} results.runs.tests.state
 * @property {string[]} results.runs.tests.title
 * @property {string} results.runs.video
 * @property {string} status
 * @property {string} startedTestsAt
 * @property {string} endedTestsAt
 * @property {object[]} cards
 * @property {string} cards.header
 * @property {number} cards.title
 * @property {any} screenshotFailed
 * @property {null[]} screenshotPassed
 * @property {array[]|string[]} testFailed
 * @property {array[]|string[]} testPassed
 * @property {any} videoFailed
 * @property {string[]} videoPassed
 */

/**
 * Sanitizes the test asset path by replacing certain parts of the path with corresponding URLs.
 * @param {string} path - The original asset path.
 * @param {Test} test - The test object containing the test ID.
 * @returns {string} - The sanitized asset path.
 */
const sanitizeImageAssetPath = (path, test) => {
  if (!path) return "";
  return path
    .replace(
      "/Users/francescofiore/Sites/skin-performance-monitor/cypress",
      `https://d3tw5e52jvhvk0.cloudfront.net/${test.id}`
    )
    .replace(
      "/root/apps/skin-performance-monitor/source/cypress",
      `https://d3tw5e52jvhvk0.cloudfront.net/${test.id}`
    )
    .replace(
      "/root/apps/skin-performance-triboo/cypress/screenshots/", // works till 2099
      `https://d3tw5e52jvhvk0.cloudfront.net/e2e/screenshots/`
    );
};

/**
 * Sanitizes the test asset path by replacing certain parts of the path with corresponding URLs.
 * @param {string} path - The original asset path.
 * @param {Test} test - The test object containing the test ID.
 * @returns {string} - The sanitized asset path.
 */
const sanitizeVideoAssetPath = (path, test) => {
  if (!path) return "";
  return path
    .replace(
      "/Users/francescofiore/Sites/skin-performance-monitor/cypress",
      `https://d3tw5e52jvhvk0.cloudfront.net/${test.id}`
    )
    .replace(
      "/root/apps/skin-performance-monitor/source/cypress",
      `https://d3tw5e52jvhvk0.cloudfront.net/${test.id}`
    )
    .replace(
      "/root/apps/skin-performance-triboo/cypress/videos/20", // works till 2099
      `https://d3tw5e52jvhvk0.cloudfront.net/e2e/run/`
    );
};

function DataTests(props) {
  console.log("Tests Component");

  return (
    <div>
      {props.data.length === 0 ? (
        <ListGroup>
          <ListGroup.Item variant="warning">
            No data found for this query
          </ListGroup.Item>
        </ListGroup>
      ) : (
        <Container>
          {props.data.map((test) => {
            if (test.status === "finished") {
              const hasScreenshotFailed = test?.screenshotFailed?.length > 0;
              const hasVideoFailed = test?.videoFailed?.length > 0;

              const hasScreenshotSuccess = test?.screenshotPassed?.length > 0;
              const hasVideoSuccess = test?.videoPassed?.length > 0;

              return (
                <Row key={"test-" + test.id}>
                  <Alert key={"light"} variant={"light"}>
                    Test id {test.id}: Completed - {test.startedTestsAt}
                  </Alert>
                  <Col md={12}>
                    <Cards data={test.cards} />
                    <Title
                      style={titleStyle}
                    >{`Reliability Check - Failed Tests - Test id ${test.id}`}</Title>
                  </Col>
                  {hasScreenshotFailed || hasVideoFailed ? (
                    <Col md={6}>
                      <div style={{ backgroundColor: "#333" }}>
                        <Gallery
                          images={test?.screenshotFailed?.map((s) => ({
                            ...s,
                            path:
                              s && s.path
                                ? sanitizeImageAssetPath(s.path, test)
                                : ``,
                          }))}
                        />
                        {test.videoFailed.map((s) => (
                          <video
                            style={videoStyle}
                            key={uuid()}
                            controls
                            poster={
                              "https://d3tw5e52jvhvk0.cloudfront.net/assets/video-placeholder.png"
                            }
                            src={sanitizeVideoAssetPath(s, test)}
                          ></video>
                        ))}
                      </div>
                    </Col>
                  ) : null}

                  <Col md={hasScreenshotFailed || hasVideoFailed ? 6 : 12}>
                    <DataTable data={test.testFailed} />
                  </Col>
                  <Title>
                    {`Quality Check - Passed Tests - Test id ${test.id}`}
                  </Title>
                  {hasScreenshotSuccess || hasVideoSuccess ? (
                    <Col md={6} style={{ backgroundColor: "#333" }}>
                      <Gallery
                        images={test?.screenshotPassed?.map((s) => ({
                          ...s,
                          path:
                            s && s.path
                              ? sanitizeImageAssetPath(s.path, test)
                              : ``,
                        }))}
                      />
                      {test.videoPassed.map((s) => (
                        <video
                          style={videoStyle}
                          key={uuid()}
                          controls
                          poster={
                            "https://d3tw5e52jvhvk0.cloudfront.net/assets/video-placeholder.png"
                          }
                          src={sanitizeVideoAssetPath(s, test)}
                        ></video>
                      ))}
                    </Col>
                  ) : null}
                  <Col md={hasScreenshotSuccess || hasVideoSuccess ? 6 : 12}>
                    <DataTable data={test.testPassed} />
                  </Col>
                </Row>
              );
            } else {
              return (
                <Alert key={"warning"} variant={"warning"}>
                  Test id {test.id}: failed - {test.startedTestsAt}
                </Alert>
              );
            }
          })}
        </Container>
      )}
    </div>
  );
}

const titleStyle = {
  margin: "20px 0 20px 0",
};

const videoStyle = {
  maxWidth: "100%",
  padding: "2%",
};

export default DataTests;
