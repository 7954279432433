//@ts-check
import React, { useRef } from "react";
import FormControl from "react-bootstrap/FormControl";
import FormCheck from "react-bootstrap/FormCheck";
import InputGroup from "react-bootstrap/InputGroup";
import Placeholder from "react-bootstrap/Placeholder";

const updateObjProp = (obj, value, propPath) => {
  const pList = propPath.split(".");
  const key = pList.pop();
  const pointer = pList.reduce((accumulator, currentValue) => {
    if (accumulator[currentValue] === undefined) accumulator[currentValue] = {};
    return accumulator[currentValue];
  }, obj);
  pointer[key] = value;
  return obj;
};

// get value from object by key

const getValue = (obj, path) => {
  if (path.includes(".")) {
    const pList = path.split(".");
    const key = pList.pop();
    const pointer = pList.reduce((accumulator, currentValue) => {
      if (accumulator[currentValue] === undefined)
        accumulator[currentValue] = {};
      return accumulator[currentValue];
    }, obj);
    return pointer[key];
  } else {
    return obj[path];
  }
};

function ParseInput(props) {
  // const intput
  const inputRef = useRef(null);

  const inputType = props.inputType ? props.inputType : "text";

  if (!props.obj) {
    return (
      <Placeholder animation="glow">
        <Placeholder xs={6} />
      </Placeholder>
    );
  }
  const handleChange = (e) => {
    console.log(props.objKey, "->", e.target.value);
    let value = e.target.value;
    if (props.type === "number") {
      value = parseFloat(value);
    }
    if (props.type === "boolean") {
      value = e.target.checked;
    }

    // if objKey is nested like "customer.mdPrice" with X levels
    // we need to access the nested object and set the value by preserving the nesting and the other keys
    if (props.objKey.includes(".")) {
      updateObjProp(props.obj, value, props.objKey);
      console.log("props.obj =>", props.obj);
      props.dispatch({
        type: `SET_${props.parseClass.toUpperCase()}`,
        payload: props.obj,
      });
    } else {
      const payload = {
        ...props.obj,
        [props.objKey]: value,
      };
      props.dispatch({
        type: `SET_${props.parseClass.toUpperCase()}`,
        payload,
      });
    }
  };

  switch (inputType) {
    case "checkbox": {
      return (
        <FormCheck
          type="checkbox"
          label={props.label}
          style={inputStyle}
          defaultChecked={getValue(props.obj, props.objKey)}
          onChange={handleChange}
          _ref={inputRef}
        />
      );
    }
    default: {
      return (
        <InputGroup className="mb-3" style={inputStyle}>
          {props.addon && (
            <InputGroup.Text id="addon">{props.addon}</InputGroup.Text>
          )}

          <FormControl
            placeholder={props.placeholder}
            aria-label={props.label}
            defaultValue={getValue(props.obj, props.objKey)}
            aria-describedby="addon"
            _ref={inputRef}
            onChange={handleChange}
          />
        </InputGroup>
      );
    }
  }
}

const inputStyle = {
  margin: "0.5rem 0rem",
};

export default ParseInput;
