//@ts-check
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { useSelector } from "react-redux";
import DataContainer from "../../components/data-container";
import TimeSelector from "../../components/time-selector";
import { Title } from "../../components/typography";
import { useParams } from "react-router-dom";
import { POContext } from "../../context/ParseObject";
import { Fragment, useContext } from "react";
import { getParseObj } from "../../functions/parse";
import ParseInput from "../../components/forms/ParseInput";
import ParseSaveButton from "./ParseSaveButton";
import { CAPACITY_SKILLS } from "../../configuration";

function ClickupUser() {
  const user = useSelector((state) => state.user);
  const time = useSelector((state) => state.time);

  const routeParams = useParams();

  const [PO, dispatch] = useContext(POContext);

  console.log("PO =>", PO);
  if (!PO.user && routeParams.userId) {
    getParseObj("ClickUpUser", routeParams.userId).then((obj) => {
      console.log("obj =>", obj);
      dispatch({ type: "SET_CLICKUPUSER", payload: obj.data });
    });
  }

  return (
    <Container style={containerStyle}>
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <h3>Team KPIs 💪</h3>
          </Col>
          <Col md="auto">
            <TimeSelector />
          </Col>
        </Row>
        {time && time.timeSelected && routeParams.userId ? (
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <Title>Main KPI this week</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cards/user/${routeParams.userId}/${time.timeSelected}`}
                    type="cards"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Title>Tasks focus</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/user/${routeParams.userId}/tasks/all/graph/${time.timeSelected}`}
                    type="graphBarHorizontal"
                  />
                </Col>
                <Col md={6}>
                  <Title>Closed Tasks</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/user/${routeParams.userId}/tasks/closedAndDoneThisWeekTasks/${time.timeSelected}/`}
                    type="table"
                  />
                  <Title>Open Tasks</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/user/${routeParams.userId}/tasks/openThisWeekTasks/${time.timeSelected}/`}
                    type="table"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  {user &&
                  user.authorisations &&
                  user.authorisations.userMDCost ? (
                    <Fragment>
                      <hr />
                      <Title>User setting:</Title>
                      <ParseInput
                        label="Man Day Cost in €"
                        addon="Man Day Cost €"
                        obj={PO.user}
                        objKey="mdPrice"
                        dispatch={dispatch}
                        type="number"
                        parseClass="ClickUpUser"
                      />
                    </Fragment>
                  ) : null}
                </Col>
                <Col md={6}>
                  {/* <hr />
                  <Title>Capacity Plan – By Skill</Title>
                  <ParseInput
                    label="BSM"
                    addon="MD €"
                    obj={PO.user}
                    objKey="skillType.BSM"
                    dispatch={dispatch}
                    type="boolean"
                    inputType="checkbox"
                    parseClass="ClickUpUser"
                  />
                  <ParseInput
                    label="CR"
                    addon="MD €"
                    obj={PO.user}
                    objKey="skillType.CR"
                    dispatch={dispatch}
                    type="boolean"
                    inputType="checkbox"
                    parseClass="ClickUpUser"
                  />
                  <ParseInput
                    label="PM"
                    addon="MD"
                    obj={PO.user}
                    objKey="skillType.PM"
                    dispatch={dispatch}
                    type="boolean"
                    inputType="checkbox"
                    parseClass="ClickUpUser"
                  />
                  <hr /> */}
                </Col>
                <Col md={12}>
                  <Title>Capacity x skill:</Title>
                  {/* for each ky in skillType */}
                  {PO.user &&
                    PO.user.teams &&
                    PO.user.teams.map((t) => {
                      // if (PO.user.skillType[key] === false) {
                      //   return null;
                      // }
                      // Add one parse input for each month starting by the current month - 3
                      const years = [2023, 2024];
                      const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
                      console.log("team =>", PO.user.teams);
                      return (
                        <Row>
                          <Title>Team: {t}</Title>
                          {/* <hr /> */}
                          {CAPACITY_SKILLS.map((key) => {
                            return (
                              <Row>
                                <Title>{key}</Title>
                                {years.map((year) => {
                                  return (
                                    <Col md={6}>
                                      <Title>{year}</Title>
                                      <Stack direction="horizontal" gap={1}>
                                        {months.map((month) => {
                                          return (
                                            <Stack direction="vertical">
                                              <Title color="#666666">
                                                {month}
                                              </Title>
                                              <ParseInput
                                                label={month}
                                                // addon="MD €"
                                                obj={PO.user}
                                                objKey={`capacity.${t}.${key}.${year}.${month}`}
                                                dispatch={dispatch}
                                                type="number"
                                                parseClass="ClickUpUser"
                                              />
                                            </Stack>
                                          );
                                        })}
                                      </Stack>
                                    </Col>
                                  );
                                })}
                              </Row>
                            );
                          })}
                        </Row>
                      );
                    })}

                  <ParseSaveButton
                    obj={PO.user}
                    dispatch={dispatch}
                    parseClass="ClickUpUser"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </Container>
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

const titles = {};

export default ClickupUser;
