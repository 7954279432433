//@ts-check
import React from "react";
import Button from "react-bootstrap/Button";

function ButtonCol({ text, url, target, size = "sm" }) {
  return (
    <Button
      style={buttonStyle}
      variant="primary"
      onClick={() => window.open(url, target)}
      size={size}
    >
      {text}
    </Button>
  );
}
const buttonStyle = {
  margin: "2rem auto 0rem auto",
};
export { ButtonCol };
