import { createStore, applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
export const store = createStore(
  reducers,
  {
    user: null,
    project: null,
    annotations: [],
    modal: null,
    overlay: null,
    time: {
      current: null,
      availableWeeks: null,
      compareOptions: [],
      timeSelected: null,
    },
  },
  applyMiddleware(thunk)
);
