//@ts-check
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import DataContainer from "../../components/data-container";
import TimeSelector from "../../components/time-selector";
import { Title } from "../../components/typography";

function ClickUpCR() {
  const time = useSelector((state) => state.time);

  const teams = [
    "SKIN",
    "FE",
    "M2",
    "Shopify",
    "ARGO",
    "PM",
    "SM",
    "CONTENT",
    "STR",
  ];

  return (
    <Container style={containerStyle}>
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <h3>Budget</h3>
          </Col>
          <Col md="auto">
            <TimeSelector />
          </Col>
        </Row>
        {time && time.timeSelected ? (
          <Row>
            <Col md={12}>
              <Row>
                <Col md={8}>
                  <Title>CR Active</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cr/cards/global/${time.timeSelected}`}
                    type="cards"
                  />
                  <Title>Budget Planned on time</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cr/customer/all/cr-budget/graph`}
                    type="graphBarHorizontalStacked"
                  />
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cr/customer/all/cr-budget/table`}
                    type="table"
                  />
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cost/customer/all/table`}
                    type="table"
                  />
                </Col>
                <Col md={4}>
                  <Title>Estimated budget by customer</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cr/totaltasks/by-customer/all/table/${time.timeSelected}`}
                    type="table"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={8}></Col>
                <Col md={4}>
                  <Title>Team</Title>

                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/user-turnover/table/${time.timeSelected}/team/all`}
                    type="table"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </Container>
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

export default ClickUpCR;
