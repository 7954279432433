//@ts-check

import React from "react";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import DataContainer from "../../components/data-container";
import TimeSelector from "../../components/time-selector";
import { LinkTitle } from "../../components/typography";
import { teams } from "./clickupHome";

function ClickupCapacity() {
  const time = useSelector((state) => state.time);

  return (
    <Container style={containerStyle}>
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <h3>Capacity Plan</h3>
          </Col>
          <Col md="auto">
            <TimeSelector />
          </Col>
        </Row>
        {time && time.timeSelected ? (
          <Row>
            <Col md={12}>
              <Row>
                <Alert variant="info">
                  Total demand is calculated based on tasks and baseline
                  allocation for each customer. Capacity is calculated based on
                  the availability of each team member of the team.
                </Alert>
              </Row>
              <Row>
                {/* for each team add a team table */}
                {teams.map((team) => (
                  <Col md={6}>
                    <LinkTitle href={`/clickup/team/${team}`}>
                      Team {team}
                    </LinkTitle>
                    <DataContainer
                      apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/capacity/team/${team}/format/graph/all`}
                      type="graphBar"
                    />
                    <DataContainer
                      apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/capacity/team/${team}/format/table/all`}
                      type="table"
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        ) : null}
      </Container>
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

export default ClickupCapacity;
