export const MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
export const MONTHS_SHORT = [
  "Jn",
  "Fb",
  "Mr",
  "Ap",
  "Ma",
  "Ju",
  "Jl",
  "Ag",
  "Sp",
  "Ot",
  "Nv",
  "Dc",
];
export const monthMap = (month) => MONTHS_SHORT[month - 1];
