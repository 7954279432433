import { useEffect, useState } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import "chart.js/auto";
import { Chart } from "react-chartjs-2";
import { useSelector } from "react-redux";
import Cards from "../cards";
import EventList from "../event";
import Sessions from "../session";
import DataTable from "../table";
import DataTests from "../tests";

function DataContainer(props) {
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);
  const [data, setData] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [fetching, setFetching] = useState(true);
  // Chart.register(CategoryScale);
  useEffect(() => {
    const loadData = async () => {
      setFetching(true);
      try {
        const response = await fetch(props.apiUrl);
        if (response.ok) {
          const fetchedData = await response.json();
          setData(fetchedData.data);
          setFetching(false);
          if (props.project && props.project.platforms) {
            setPlatforms(props.project.platforms);
          }
          console.log("data fetched");
        } else {
          setData(null);
          setFetching(false);
          console.log("Cannot fetch");
        }
      } catch (error) {
        setData(null);
        setFetching(false);
        console.log("Cannot fetch", error);
      }
    };
    loadData();
  }, [props.apiUrl, project, time.timeSelected]);

  // console.log('DataContainer', props.type, '-> data ->', data)

  const renderSwitch = (type) => {
    switch (type) {
      case "graphLine":
        return <Chart type="line" data={data} />;
      case "graphBar":
        return <Chart type="bar" data={data} />;
      case "graphBarHorizontal":
        return <Chart type="bar" data={data} options={{ indexAxis: "y" }} />;
      case "graphBarHorizontalStacked":
        return (
          <Chart
            type="bar"
            data={data}
            options={{
              indexAxis: "x",
              scales: { x: { stacked: true }, y: { stacked: true } },
            }}
          />
        );
      case "table":
        return <DataTable data={data} />;
      case "tests":
        return <DataTests data={data} />;
      case "cards":
        return <Cards data={data} platforms={platforms} />;
      case "sessions":
        return <Sessions data={data} colors={props.colors} />;
      case "events":
        return <EventList data={data} colors={props.colors} />;
      case "radar":
        return <Chart type="radar" data={data} />;
      default:
        return <div>Type of component not managed</div>;
    }
  };

  return (
    <div>
      {!data || fetching === true ? (
        <Placeholder as="p" animation="glow" style={placeholderContainerStyle}>
          <Placeholder xs={12} style={phStyle} />
        </Placeholder>
      ) : (
        <div style={dataContainerStyle}>{renderSwitch(props.type)}</div>
      )}
    </div>
  );
}
const dataContainerStyle = {
  marginTop: "20px",
};
const placeholderContainerStyle = {
  padding: "25px 0",
  opacity: "0.25",
};
const phStyle = {
  height: "40px",
};

export default DataContainer;
