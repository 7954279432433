import { combineReducers } from "redux";
import userReducer from "./userReducer";
import projectReducer from "./projectReducer";
import timeReducer from "./timeReducer";
import annotationReducer from "./annotationReducer";
import modalReducer from "./modalReducer";
import overlayReducer from "./overlayReducer";

const reducers = combineReducers({
  user: userReducer,
  project: projectReducer,
  time: timeReducer,
  annotations: annotationReducer,
  modal: modalReducer,
  overlay: overlayReducer,
});

export default reducers;
