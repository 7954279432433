//@ts-check
import React from "react";
import axios from "axios";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function E2E() {
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);

  const [isLoading, setLoading] = useState(false);

  const scheduleE2E = () => {
    console.log("schedule e2e");
    setLoading(true);
    axios.post(
      `${process.env.REACT_APP_SKIN_MONITORING_URL}/api/cypress/schedule`,
      { url: project.name.split(" ")[0] }
    );
  };

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>

            <Col md="auto">
              <Button
                variant={!isLoading ? "primary" : "success"}
                size="md"
                disabled={isLoading}
                onClick={() => (!isLoading ? scheduleE2E() : null)}
              >
                {!isLoading ? "Schedule new test" : "Scheduled"}
              </Button>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col md={12}>
                <Title>E2E Ultime 5 run</Title>

                <DataContainer
                  apiUrl={`${
                    process.env.REACT_APP_SKIN_MONITORING_URL
                  }/api/cypress/${project.name.split(" ")[0]}/5`}
                  type="tests"
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

const titles = {};

export default E2E;
