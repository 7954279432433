//@ts-check
import React from "react";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import { LinkContainer } from "react-router-bootstrap";

function Sessions(props) {
  return (
    <ListGroup>
      {props.data.length === 0 ? (
        <ListGroup.Item variant="warning">
          No sessions found or data is caching ...
        </ListGroup.Item>
      ) : (
        props.data.map((li) => {
          console.log(li);
          return (
            <LinkContainer
              to={`/journeys/${li.id}`}
              key={li.id}
              style={linkStyle}
            >
              <ListGroupItem
                as="li"
                className="d-flex justify-content-between align-items-center"
                variant={
                  props.colors &&
                  props.colors === true &&
                  li.step === "Purchase"
                    ? "success"
                    : null
                }
                key={li.id}
                action
                href={`/journeys/${li.id}`}
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{li.step}</div>
                  {li.id}
                </div>
                {li.user && li.user === "logged" ? (
                  <div className="ms-2 me-auto">
                    <div className="fw-bold">Logged</div>
                  </div>
                ) : null}
                {li.checkoutError ? (
                  <Badge bg="danger" pill style={badgeStyle}>
                    Check Err #{li.checkoutError}
                  </Badge>
                ) : null}
                {li.clientError ? (
                  <Badge bg="danger" pill style={badgeStyle}>
                    Err #{li.clientError}
                  </Badge>
                ) : null}
                {li.checkoutWarning ? (
                  <Badge bg="warning" pill style={badgeStyle}>
                    Alerts #{li.checkoutWarning}
                  </Badge>
                ) : null}
                <Badge bg="secondary" pill style={badgeStyle}>
                  Events #{li.eventNr}
                </Badge>
                <span style={badgeStyle}>{li.date}</span>
              </ListGroupItem>
            </LinkContainer>
          );
        })
      )}
    </ListGroup>
  );
}
const badgeStyle = {
  margin: "auto 4px",
};
const linkStyle = {
  textDecoration: "none",
};
export default Sessions;
