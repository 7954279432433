// function Title(props) {
//   return (
//     <h2 style={titleStyle}>{props.text}</h2>
//   )
// }
// const titleStyle = {
//   margin: '2rem auto 0rem auto',
//   fontSize: '1rem'
// }

// Refactor as styled component
import styled from "styled-components";

const Title = styled.h2`
  margin: 2rem auto 0rem auto;
  font-size: 1rem;
  color: ${(props) => props.color || "white"};
  margin-bottom: ${(props) => props.mb || "0px"};
`;
const LinkTitle = styled.a`
  margin: 2rem auto 0rem auto;
  font-size: 1rem;
  color: ${(props) => props.color || "white"};
  margin-bottom: ${(props) => props.mb || "0px"};
`;

export { Title, LinkTitle };
