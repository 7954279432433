//@ts-check
import React, { Fragment } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function Errors() {
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col md={6}>
                <Title>Business KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/business/${time.timeSelected}`}
                  type="cards"
                />
                <Title>Client Error KPIS</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/errors/${time.timeSelected}/all`}
                  type="cards"
                />
                <Title>🔍 Error Trend</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/client-error/${project.id}/graph/daily/${time.timeSelected}`}
                  type="graphLine"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/client-error/${project.id}/graph/recent`}
                  type="graphLine"
                />
                <Title>Error details</Title>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/client-error/${project.id}/table/${time.timeSelected}/all`}
                  type="table"
                />
                <Title>🚨 500 Errors</Title>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/client-error/${project.id}/table/${time.timeSelected}/500/all`}
                  type="table"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/client-error/${project.id}/table/${time.timeSelected}/500/all?group=message`}
                  type="table"
                />
                <Title>Checkout Error details</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/errors/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
              </Col>
              <Col md={6}>
                <Title>Localised Strings Errors:</Title>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/client-error/${project.id}/string-warning`}
                  type="table"
                />
                <Title>🚨 404 Errors</Title>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/client-error/${project.id}/table/${time.timeSelected}/404/all`}
                  type="table"
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

const titles = {};

export default Errors;
