//@ts-check
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function Checkout() {
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col md={6}>
                <Title>Business KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/business/${time.timeSelected}`}
                  type="cards"
                />

                <Title>Checkout Click Funnel</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/click/${project.id}/graph/${time.timeSelected}`}
                  type="graphBarHorizontal"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/click/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>Checkout Funnel</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/${project.id}/graph/${time.timeSelected}`}
                  type="graphBarHorizontal"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
              </Col>
              <Col md={6}>
                <Title>Checkout KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/checkout/${time.timeSelected}/all`}
                  type="cards"
                />
                <Title>Checkout KPIs - Mobile</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/checkout/${time.timeSelected}/mobile`}
                  type="cards"
                />
                <Title>This week daily error trend and %completion rate</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/client-error/${project.id}/graph/daily/${time.timeSelected}`}
                  type="graphLine"
                />
                <Title>Checkout Error details</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/errors/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>Checkout Full Funnel</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/full/${project.id}/graph/${time.timeSelected}`}
                  type="graphBarHorizontal"
                />
                <Title>Latest 15 checkout journeys</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/journeys/${project.id}/${time.timeSelected}?limit=15&checkoutStep=1`}
                  type="sessions"
                  colors={false}
                />
                <Title>Warnings details</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/warning/${project.id}/table/${time.timeSelected}/all`}
                  type="table"
                />
                <Title>Warnings details mobile</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/warning/${project.id}/table/${time.timeSelected}/mobile`}
                  type="table"
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

export default Checkout;
