const reducer = (
  state = {
    active: false,
    type: null,
  },
  action
) => {
  switch (action.type) {
    case "setOverlay":
      console.log("setOver =>", action.payload);
      return { ...action.payload };
    default:
      return state;
  }
};
export default reducer;
