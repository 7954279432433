const reducer = (state = null, action) => {
  switch (action.type) {
    case "loginUser":
      console.log("loginUser =>", action.payload);
      return { ...action.payload };
    case "logoutUser":
      console.log("logoutUser =>", action.payload);
      return null;
    default:
      return state;
  }
};
export default reducer;
