//@ts-check
import React from "react";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";
import { useParams } from "react-router-dom";

function SessionPage(props) {
  const project = useSelector((state) => state.project);
  const [session, setSession] = useState(null);
  const { sessionId } = useParams();
  console.log("SessionId =>", sessionId);
  // console.log(props)
  useEffect(() => {
    const fetchSession = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/session/${project.id}/${sessionId}`
      );
      // console.log(response)
      const sessionData = await response.json();
      console.log("Session data =>", sessionData.data);
      setSession(sessionData.data);
    };
    if (project && project.id) {
      fetchSession();
    }
  }, [project]);

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{sessionId}</h3>
            </Col>
          </Row>

          {session ? (
            <Row>
              <Col>
                <Badge bg="secondary" pill style={badgeStyle}>
                  Funnel: {session.funnelStep}
                </Badge>
                <Badge bg="secondary" pill style={badgeStyle}>
                  Events: {session.eventNr}
                </Badge>
                <Badge bg="secondary" pill style={badgeStyle}>
                  Page Views: {session.pageViews}
                </Badge>
                {session.checkoutWarning && session.checkoutWarning > 0 ? (
                  <Badge bg="warning" pill style={badgeStyle}>
                    Warning: {session.checkoutWarning}
                  </Badge>
                ) : null}
                {session.checkoutError && session.checkoutError > 0 ? (
                  <Badge bg="danger" pill style={badgeStyle}>
                    Check Err: {session.checkoutError}
                  </Badge>
                ) : null}
                {session.checkoutError && session.clientError > 0 ? (
                  <Badge bg="danger" pill style={badgeStyle}>
                    Error: {session.clientError}
                  </Badge>
                ) : null}
                {session.checkoutStep ? (
                  <Badge bg="secondary" style={badgeStyle} pill>
                    {" "}
                    Checkout: {session.checkoutStep}
                  </Badge>
                ) : null}
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md={6}>
              <Title>Customer Journey</Title>
              <DataContainer
                apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/event/${project.id}/by-session/${sessionId}`}
                type="events"
                colors={true}
              />
            </Col>
          </Row>
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};
const badgeStyle = {
  marginRight: ".5rem",
};

export default SessionPage;
