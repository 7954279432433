//@ts-check
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import DataContainer from "../../components/data-container";
import TimeSelector from "../../components/time-selector";
import { LinkTitle, Title } from "../../components/typography";

export const teams = [
  "SKIN",
  "FE",
  "M2",
  "Shopify",
  "ARGO",
  "PM",
  // "SM",
  // "CONTENT",
  // "STR",
];

function ClickUpHome() {
  const time = useSelector((state) => state.time);

  return (
    <Container style={containerStyle}>
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <h3>Team KPIs 💪</h3>
          </Col>
          <Col md="auto">
            <TimeSelector />
          </Col>
        </Row>
        {time && time.timeSelected ? (
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <Title>Task Turnover</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cards/global/${time.timeSelected}`}
                    type="cards"
                  />
                </Col>
                <Col md={6}>
                  <Title>Task Turnover this week by type</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/turnover/table/${time.timeSelected}`}
                    type="table"
                  />
                </Col>
              </Row>

              <Row>
                <Col md={6}>
                  <Title>Task Turnover Trend by Week</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/turnover/graph`}
                    type="graphLine"
                  />
                </Col>
                <Col md={6}>
                  <Title>Task Turnover this week by type</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/turnover/graph/by-type/${time.timeSelected}`}
                    type="graphBarHorizontal"
                  />
                </Col>
              </Row>
              <Row>
                {/* for each team add a team table */}
                {teams.map((team) => (
                  <Col md={4}>
                    <LinkTitle href={`/clickup/team/${team}`}>
                      Team {team}
                    </LinkTitle>
                    <DataContainer
                      apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/user-turnover/table/${time.timeSelected}/team/${team}`}
                      type="table"
                    />
                  </Col>
                ))}
              </Row>
              <Title>Task Turnover by Customer</Title>
              <DataContainer
                apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/totaltasks/all/${time.timeSelected}`}
                type="graphBarHorizontalStacked"
              />
              <Row>
                <Col md={8}>
                  <Title>Activities by type grouped by customer</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/totaltasks/by-customer/all/table/${time.timeSelected}`}
                    type="table"
                  />
                </Col>
                <Col md={4}>
                  <Title>Team</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/user-turnover/table/${time.timeSelected}/team/all`}
                    type="table"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </Container>
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

export default ClickUpHome;
