//@ts-check
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function Home() {
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col md={6}>
                <Title>💰 Business KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/business/${time.timeSelected}`}
                  type="cards"
                />
                <Title>🔍 Week by day</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/business/${project.id}/graph/daily/${time.timeSelected}`}
                  type="graphLine"
                />
                <Title>🚀 Speed KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/speed/${time.timeSelected}`}
                  type="cards"
                />

                <Title>⛵ Journey Funnel</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/funnel/${project.id}/graph/${time.timeSelected}/all`}
                  type="graphBar"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/funnel/${project.id}/table/${time.timeSelected}/all`}
                  type="table"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/funnel/${project.id}/graph/recent/all`}
                  type="graphLine"
                />
                <Title>📱 Journey Funnel - Mobile</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/funnel/${project.id}/graph/recent/mobile`}
                  type="graphLine"
                />

                <Title>💵 Checkout Funnel</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/${project.id}/graph/${time.timeSelected}`}
                  type="graphBarHorizontal"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>🔍 Contextual Analysis trends</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/context/${project.id}/graph/recent`}
                  type="graphLine"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/context/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
              </Col>
              <Col md={6}>
                <Title>Client Error KPIS</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/errors/${time.timeSelected}/all`}
                  type="cards"
                />
                <Title>Checkout KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/checkout/${time.timeSelected}/all`}
                  type="cards"
                />
                <Title>Store potential KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/store-potential/${time.timeSelected}`}
                  type="cards"
                />

                <Title>Latest 10 purchase journeys</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/journeys/${project.id}/${time.timeSelected}?limit=10&funnelStep=4`}
                  type="sessions"
                  colors={false}
                />
                <Title>Landing Types</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/types/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>Top 20 Product by Traffic</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/${project.id}/table/${time.timeSelected}?limit=20&type=product`}
                  type="table"
                />
                <Title>Top 20 Listings by Traffic</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/${project.id}/table/${time.timeSelected}?limit=20&type=listing`}
                  type="table"
                />
                <Title>High potential SKUs Out of Stock %</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/stock-data/${project.id}/recent`}
                  type="graphLine"
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

const titles = {};

export default Home;
