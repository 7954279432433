const reducer = ( state = { 
  current: null,
  availableWeeks: null,
  compareOptions: [], timeSelected: null}, action) => {
  switch (action.type) {
    case "setTime":
      console.log('setTime =>', action.payload)
      return { ...action.payload }
    default:
      return state
  }
}
export default reducer