// Img Gallery
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import "lightgallery/css/lg-video.css";

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";

function Gallery({ images, videos }) {
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };
  return (
    <LightGallery
      onInit={onInit}
      speed={500}
      plugins={[lgThumbnail, lgZoom, lgVideo]}
      style={galleryStyle}
    >
      {images
        ? images.map((image, index) => (
            <a key={"img" + index} href={image.path} style={linkStyle}>
              <img src={image.path} alt={image.title} style={imgStyle} />
            </a>
          ))
        : null}
      {videos
        ? videos.map((video, index) => (
            <a
              key={index}
              href={video}
              style={linkStyle}
              data-video={`{"source": [{"src":"${video}", "type":"video/mp4"}], "attributes": {"preload": false, "controls": true}}`}
              data-poster="https://image.mux.com/4Rt5Z008g1rXb00diCFn3LjBngvo5pj7Mq/thumbnail.jpg"
              data-sub-html="<h4>Video</h4>"
            >
              <img
                src={
                  "https://d3tw5e52jvhvk0.cloudfront.net/assets/video-placeholder.png"
                }
                alt={video}
                style={imgStyle}
              />
            </a>
          ))
        : null}

      {/* <a href="img/img1.jpg">
                    <img alt="img1" src="img/thumb1.jpg" />
                </a>
                <a href="img/img2.jpg">
                    <img alt="img2" src="img/thumb2.jpg" />
                </a>
                ... */}
    </LightGallery>
  );
}

const linkStyle = {
  backgroundColor: "#333",
};

const galleryStyle = {
  width: "100%",
};
const imgStyle = {
  maxWidth: "46%",
  maxHeight: "500px",
  margin: "2%",
};

export default Gallery;
