import { createContext, useReducer } from "react";

export const POContext = createContext();

/**
 * @typedef {object} AppState
 * @property {object?} user
 * @property {string} user.id
 * @property {string} user.name
 * @property {string} user.initials
 * @property {object[]} user.projects
 * @property {string} user.projects.id
 * @property {string} user.projects.name
 * @property {string} user.projects.adminURL
 * @property {string} user.projects.websiteURL
 * @property {string} user.projects.defaultStore
 * @property {string[]} user.projects.platforms
 * @property {string} user.projects.env
 * @property {number} user.projects.priority
 * @property {object} user.authorisations
 * @property {boolean} user.authorisations.loop
 * @property {boolean} user.authorisations.clickup
 * @property {object} project
 * @property {string} project.id
 * @property {string} project.name
 * @property {string[]} project.platforms
 * @property {string} project.env
 * @property {number} project.priority
 * @property {object} time
 * @property {string} time.current
 * @property {string[]} time.availableWeeks
 * @property {any} time.compareOptions
 * @property {string} time.timeSelected
 * @property {any} annotations
 * @property {null} modal
 * @property {null} overlay
 * @property {any} customer
 * @property {any} team
 */

/**
 * @type {AppState}
 */
const initialState = {
  customer: null,
  user: null,
  team: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_CLICKUPCUSTOMER":
      return {
        ...state,
        customer: action.payload,
      };
    case "SET_CLICKUPUSER":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_CLICKUPTEAM":
      return {
        ...state,
        team: action.payload,
      };
    case "SET_TEAM":
      return {
        ...state,
        team: action.payload,
      };

    default:
      return {
        state,
      };
  }
};

export const POContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <POContext.Provider value={[state, dispatch]}>
      {props.children}
    </POContext.Provider>
  );
};
