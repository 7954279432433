//@ts-check
import React from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import { updateParseObj } from "../../functions/parse";
import { useState } from "react";

function ParseSaveButton(props) {
  const [isLoading, setLoading] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const handleClick = () => {
    setLoading(true);
    console.log(props.parseClass, props.obj.objectId, props.obj);
    delete props.obj.createdAt;
    delete props.obj.updatedAt;

    updateParseObj(props.parseClass, props.obj.objectId, props.obj).then(
      (res) => {
        console.log("✔️", props.parseClass, "Saved");
        setLoading(false);
        setSaved(true);
        setTimeout(() => {
          setSaved(false);
        }, 2000);
      }
    );
  };

  return (
    <Button
      variant={isSaved ? "success" : "primary"}
      disabled={isLoading}
      onClick={!isLoading ? handleClick : null}
    >
      {isLoading ? (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      ) : isSaved ? (
        "Saved"
      ) : (
        "Save"
      )}
    </Button>
  );
}

export default ParseSaveButton;
