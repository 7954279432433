//@ts-check
import React from "react";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import CardBody from "react-bootstrap/CardBody";
import CardFooter from "react-bootstrap/CardFooter";
import CardImg from "react-bootstrap/CardImg";
import CardText from "react-bootstrap/CardText";
import CardTitle from "react-bootstrap/CardTitle";
import CardGroup from "react-bootstrap/CardGroup";
import { v4 as uuid } from "uuid";

function Cards(props) {
  const isCardVisible = (card) => {
    const platforms = props.platforms ?? [];
    if (platforms.length === 1 && platforms[0] === "skin-e2e") {
      switch (card.header) {
        case "E2E Daily Test Run":
        case "E2E Daily Test Err%":
        case "E2E Last Run":
          return true;
        default:
          return false;
      }
    } else {
      return true;
    }
  };
  return (
    <CardGroup>
      {props.data
        .filter((card) => isCardVisible(card))
        .map((card) => (
          <Card className="text-center" key={uuid()}>
            {card.header ? <CardHeader> {card.header}</CardHeader> : null}
            <CardBody>
              {card.image ? <CardImg src={card.image} /> : null}
              {card.title ? <CardTitle> {card.title}</CardTitle> : null}
              {card.body ? <CardText> {card.body}</CardText> : null}
            </CardBody>
            {card.footer ? <CardFooter> {card.footer}</CardFooter> : null}
          </Card>
        ))}
    </CardGroup>
  );
}

export default Cards;
