//@ts-check
import React from "react";
import { useEffect } from "react";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";

function printPropsForEventName(name, data) {
  switch (name) {
    case "googleAddress":
      delete data.option.id;
      delete data.option.value;
      break;

    default:
      if (data.uniqueId) {
        delete data.uniqueId;
      }
      break;
  }
  let print = "";
  Object.keys(data).forEach((key) => {
    if (typeof data[key] === "object" && data[key] !== null) {
      console.log("data[key]", data[key]);
      print += `=> ${key}:\n${printObject(data[key])}\n`;
    } else {
      print += `${key}: ${data[key]}\n`;
    }
  });
  return print;
}

function printObject(obj) {
  let print = "";
  Object.keys(obj).forEach((key) => {
    print += `   > ${key}: ${obj[key]}\n`;
  });
  return print;
}

function getStyleForEventName(name) {
  let style = "light";
  let text = "dark";
  // {li.name === "clientError" ? "🔺" : null}
  // {li.name === "checkoutError" ? "⚠️" : null}
  // {li.name === "checkoutWarning" ? "🟡" : null}
  // {li.name === "purchase" ? "🎉" : null}
  switch (name) {
    case "clientError":
    case "checkoutError":
      style = "danger";
      text = "white";
      break;
    case "checkoutButtonClick":
      style = "black";
      text = "white";
      break;
    case "checkoutWarning":
      style = "warning";
      break;

    case "purchase":
      style = "success";
      text = "white";
      break;

    case "login":
    case "addToCart":
      style = "primary";
      text = "white";
      break;
    case "sessionStart":
      style = "info";
      break;

    default:
      break;
  }
  return { bg: style, text };
}

function EventList(props) {
  useEffect(() => {
    console.log("Checking if anchor");
    window.location.hash = window.decodeURIComponent(window.location.hash);
    const scrollToAnchor = () => {
      const hashParts = window.location.hash.split("#");
      console.log("Hash parts", hashParts);
      if (hashParts.length > 1) {
        console.log("Checking if anchor =>  true");
        const hash = hashParts.slice(-1)[0];
        const sel = document.querySelector(`#eid-${hash}`);
        if (sel) {
          sel.scrollIntoView();
        }
      }
    };
    scrollToAnchor();
    window.onhashchange = scrollToAnchor;
  }, [props.data]);
  return (
    <Table striped bordered hover size="sm" style={tableStyle}>
      {props.data.length === 0 ? (
        <ListGroup.Item variant="warning">No events loaded</ListGroup.Item>
      ) : (
        <tbody>
          {props.data.map((li) => {
            return (
              <tr key={li.id} id={`eid-${li.id}`}>
                <td>
                  <Badge
                    bg={getStyleForEventName(li.name).bg}
                    text={getStyleForEventName(li.name).text}
                    style={eventNameStyle}
                  >
                    {li.name}
                  </Badge>
                </td>
                <td style={paragraphStyle}>
                  {printPropsForEventName(li.name, li.data)}
                </td>
                <td>
                  {li.date}
                  <br />
                  {li.fancyDate}
                </td>
              </tr>
            );
          })}
        </tbody>
      )}
    </Table>
  );
}

const tableStyle = {
  width: "100%",
};

const badgeStyle = {
  margin: "auto 4px",
};
const eventNameStyle = {
  fontSize: "1em",
};
const paragraphStyle = {
  maxWidth: "20rem",
  wordWrap: "break-word",
  whiteSpace: "pre",
  lineHeight: "1rem",
};
export default EventList;
