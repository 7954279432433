//@ts-check
import React from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import { useSelector } from "react-redux";
import DataContainer from "../../components/data-container";
import TimeSelector from "../../components/time-selector";
import { Title } from "../../components/typography";
import { useParams } from "react-router-dom";
import ParseInput from "../../components/forms/ParseInput";
import { useContext } from "react";
import { POContext } from "../../context/ParseObject";
import { getParseObj } from "../../functions/parse";
import ParseSaveButton from "./ParseSaveButton";
import { teams } from "./clickupHome";
import { monthMap } from "../../functions/time";

function ClickupCustomer() {
  const time = useSelector((state) => state.time);

  const [PO, dispatch] = useContext(POContext);
  const routeParams = useParams();

  const years = [2023, 2024];
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  console.log("PO =>", PO);
  if (!PO.customer && routeParams.customerId) {
    getParseObj("ClickUpCustomer", routeParams.customerId).then((obj) => {
      console.log("obj =>", obj);
      dispatch({ type: "SET_CLICKUPCUSTOMER", payload: obj.data });
    });
  }

  return (
    <Container style={containerStyle}>
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <h3>Team KPIs 💪</h3>
          </Col>
          <Col md="auto">
            <TimeSelector />
          </Col>
        </Row>
        {time && time.timeSelected && routeParams.customerId ? (
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/capacity/cards/customer/${routeParams.customerId}`}
                    type="cards"
                  />
                </Col>
                <Col md={6}>
                  <Title>Customer Budget</Title>
                  {/* /customer/:customerId/cr-budget/:format */}
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cr/customer/${routeParams.customerId}/cr-budget/graph`}
                    type="graphBarHorizontalStacked"
                  />
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cr/customer/${routeParams.customerId}/cr-budget/table`}
                    type="table"
                  />
                  <Alert variant="info">
                    Based on task configuration (ticket type, due date and time
                    estimate) we do display the planned budget for the CR
                  </Alert>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cost/customer/${routeParams.customerId}/graph`}
                    type="graphBarHorizontalStacked"
                  />
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cost/customer/${routeParams.customerId}/table`}
                    type="table"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Title>CR Tasks</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/clickup/cr/customer/${routeParams.customerId}/cr-tasks/${time.timeSelected}`}
                    type="table"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Title mb="1rem">Customer Price List:</Title>
                  {/* for each team add man day price */}
                  <Alert variant="info">
                    Define Price list to simulate Revenues for CR and services
                    for each teams. This is our Time Material price list for the
                    team.
                  </Alert>
                  {teams.map((team) => {
                    return (
                      <ParseInput
                        label="Man Days Price in €"
                        addon={`${team} Man Day Price €`}
                        obj={PO.customer}
                        objKey={`teamMDPrice.${team}`}
                        dispatch={dispatch}
                        type="number"
                        parseClass="ClickUpCustomer"
                      />
                    );
                  })}

                  <ParseSaveButton
                    obj={PO.customer}
                    dispatch={dispatch}
                    parseClass="ClickUpCustomer"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Title mb="1rem">BAM/Business Care Revenues Budget:</Title>
                  <Alert variant="info">
                    Business care or BAM Revenues budget at contract. K/€ per
                    month.
                  </Alert>
                  <ParseInput
                    label="Has Business Care"
                    // addon={`Business Care`}
                    obj={PO.customer}
                    objKey={`businessCareContract`}
                    dispatch={dispatch}
                    type="boolean"
                    inputType="checkbox"
                    parseClass="ClickUpCustomer"
                  />
                  <Row>
                    {years.map((year) => {
                      return (
                        <Col md={6}>
                          <Title>{year}</Title>
                          <Stack direction="horizontal" gap={1}>
                            {months.map((month) => {
                              return (
                                <Stack direction="vertical">
                                  <Title color="#666666">
                                    {monthMap(month)}
                                  </Title>
                                  <ParseInput
                                    label={month}
                                    // addon="MD €"
                                    obj={PO.customer}
                                    objKey={`BAMBudget.${year}.${month}`}
                                    dispatch={dispatch}
                                    type="number"
                                    parseClass="ClickUpCustomer"
                                  />
                                </Stack>
                              );
                            })}
                          </Stack>
                        </Col>
                      );
                    })}
                  </Row>
                  <Title mb="1rem">BAM/Business Cost Budget:</Title>
                  <Alert variant="info">
                    BAM Allocated Cost in Budget. K/€ per month.
                  </Alert>

                  <Row>
                    {years.map((year) => {
                      return (
                        <Col md={6}>
                          <Title>{year}</Title>
                          <Stack direction="horizontal" gap={1}>
                            {months.map((month) => {
                              return (
                                <Stack direction="vertical">
                                  <Title color="#666666">
                                    {monthMap(month)}
                                  </Title>
                                  <ParseInput
                                    label={month}
                                    // addon="MD €"
                                    obj={PO.customer}
                                    objKey={`BAMCost.${year}.${month}`}
                                    dispatch={dispatch}
                                    type="number"
                                    parseClass="ClickUpCustomer"
                                  />
                                </Stack>
                              );
                            })}
                          </Stack>
                        </Col>
                      );
                    })}
                  </Row>

                  <ParseSaveButton
                    obj={PO.customer}
                    dispatch={dispatch}
                    parseClass="ClickUpCustomer"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Title mb="1rem">Startup Budget:</Title>
                  <Alert variant="info">
                    If the customer has a replatforming or startup at contract,
                    revenue in K/€ per month.
                  </Alert>

                  <Row>
                    {years.map((year) => {
                      return (
                        <Col md={6}>
                          <Title>{year}</Title>
                          <Stack direction="horizontal" gap={1}>
                            {months.map((month) => {
                              return (
                                <Stack direction="vertical">
                                  <Title color="#666666">
                                    {monthMap(month)}
                                  </Title>
                                  <ParseInput
                                    label={month}
                                    // addon="MD €"
                                    obj={PO.customer}
                                    objKey={`StartupBudget.${year}.${month}`}
                                    dispatch={dispatch}
                                    type="number"
                                    parseClass="ClickUpCustomer"
                                  />
                                </Stack>
                              );
                            })}
                          </Stack>
                        </Col>
                      );
                    })}
                  </Row>

                  <ParseSaveButton
                    obj={PO.customer}
                    dispatch={dispatch}
                    parseClass="ClickUpCustomer"
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Title mb="1rem">Planned demand BSM Baseline per team:</Title>
                  <Alert variant="info">
                    Allocate the planned demand per team and per month. This
                    allocated deman counts as BSM Baseline for the customer.
                  </Alert>
                  {/* for each ky in skillType */}
                  {PO.customer &&
                    teams.map((key) => {
                      // Add one parse input for each month starting by the current month - 3

                      return (
                        <Row>
                          <Title>{key}</Title>
                          {years.map((year) => {
                            return (
                              <Col md={6}>
                                <Title>{year}</Title>
                                <Stack direction="horizontal" gap={1}>
                                  {months.map((month) => {
                                    return (
                                      <Stack direction="vertical">
                                        <Title color="#666666">
                                          {monthMap(month)}
                                        </Title>
                                        <ParseInput
                                          label={month}
                                          // addon="MD €"
                                          obj={PO.customer}
                                          objKey={`BSMDemand.${key}.${year}.${month}`}
                                          dispatch={dispatch}
                                          type="number"
                                          parseClass="ClickUpCustomer"
                                        />
                                      </Stack>
                                    );
                                  })}
                                </Stack>
                              </Col>
                            );
                          })}
                        </Row>
                      );
                    })}

                  <ParseSaveButton
                    obj={PO.customer}
                    dispatch={dispatch}
                    parseClass="ClickUpCustomer"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </Container>
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

export default ClickupCustomer;
