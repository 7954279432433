//@ts-check
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
function EntityCard(props) {
  return (
    <div>
      {
        props.card ? (
          <a href={props.card.url} rel="noopener noreferrer" style={linkStyle}>
            <Row style={cardStyle}>
              <Col md="auto">
                <img
                  src={props.card.imageUrl}
                  width="24"
                  height="auto"
                  alt={props.card.name}
                />
              </Col>
              <Col>
                <Row>
                  <strong>{props.card.name}</strong>
                </Row>
                <Row>
                  <span>{props.card.sku}</span>
                </Row>
              </Col>
            </Row>
          </a>
        ) : null // or put placeholder
      }
    </div>
  );
}
const cardStyle = {
  alignItems: "center",
};
const linkStyle = {
  lineHeight: "120%",
  color: "white",
  textDecoration: "none",
};

export default EntityCard;
