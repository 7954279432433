//@ts-check
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";

import { useSelector } from "react-redux";

function WelcomeBack(props) {
  const user = useSelector((state) => state.user);

  const resetAndLogin = () => {
    const local =
      process.env.REACT_APP_DATALAYER_SERVER_URL.indexOf("localhost") > 0;
    if (local) {
      document.cookie =
        "logged=; path=/; domain=localhost; expires=" +
        new Date(0).toUTCString();
      window.location = "http://localhost:8585";
    } else {
      document.cookie =
        "logged=; path=/; domain=.skin-headless.com; expires=" +
        new Date(0).toUTCString();
      window.location = process.env.REACT_APP_DATALAYER_SERVER_URL;
    }
  };

  const [loadingProgress, setLoadingProgress] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress((loadingProgress) => loadingProgress + 1);
    }, 50);
    return () => clearInterval(interval);
  });

  return (
    <Container>
      {user && user.name ? (
        <div>
          <Row className="justify-content-md-center">
            <Col>
              <h2>Bentornato {user.name}</h2>
              <Alert variant="info">
                Seleziona un progetto per iniziare l'analisi.
              </Alert>
            </Col>
          </Row>
        </div>
      ) : props.loading === false ? (
        <div>
          <Row className="justify-content-md-center">
            <Col>
              <h2>Effettua il login</h2>
              <Button onClick={() => resetAndLogin()}>Login</Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <span>{process.env.REACT_APP_DATALAYER_SERVER_URL}</span>
            </Col>
          </Row>
        </div>
      ) : (
        <ProgressBar animated now={loadingProgress} style={progressBarStyle} />
      )}
    </Container>
  );
}
const progressBarStyle = {
  marginTop: "0",
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",

  height: "3px",
};
export default WelcomeBack;
