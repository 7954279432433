//@ts-check
import React from "react";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Table from "react-bootstrap/Table";
import { v4 as uuid } from "uuid";
import EntityCard from "../entity-cards";

function DataTable(props) {
  console.log("Table Data =>", props.data);
  const header = props.data.length > 0 ? props.data[0] : [];
  const body =
    props.data.length > 1 ? props.data.slice(1, props.data.length) : [];

  return (
    <div>
      {props.data.length === 0 ? (
        <ListGroup>
          <ListGroupItem variant="warning">
            No data found for this query
          </ListGroupItem>
        </ListGroup>
      ) : (
        <Table striped bordered hover size="sm" style={tableStyle}>
          <thead>
            <tr>
              {header.map((col) => (
                <th key={uuid()}>{col}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {body.map((row) => {
              return (
                <tr style={tdStyle} key={uuid()}>
                  {row.map((col) => {
                    if (col && typeof col === "object") {
                      if (col.cardType) {
                        return (
                          <td style={tdStyle} key={uuid()}>
                            <EntityCard card={col} />
                          </td>
                        );
                      }
                      if (col.image) {
                        return (
                          <td style={tdStyle} key={uuid()}>
                            <img
                              src={col.image}
                              alt={col.text}
                              style={{ width: "100%" }}
                            />
                          </td>
                        );
                      }
                      if (col.link) {
                        return (
                          <td style={tdStyle} key={uuid()}>
                            <a style={aStyle} href={col.link}>
                              {col.text}
                            </a>
                          </td>
                        );
                      }
                      if (col.html) {
                        return (
                          <td style={tdStyle} key={uuid()}>
                            <div
                              dangerouslySetInnerHTML={{ __html: col.html }}
                            />
                          </td>
                        );
                      }
                    }
                    return (
                      <td style={tdStyle} key={uuid()}>
                        {col}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

const tableStyle = {
  // tableLayout: "fixed",
  width: "100%",
};

const tdStyle = {
  alignItems: "center",
  wordWrap: "break-word",
  maxWidth: "600px",
};

const aStyle = {
  color: "white",
  textDecoration: "none",
  borderBottom: "1px dotted grey",
};

export default DataTable;
