//@ts-check
import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { actionCreators } from "../../state";
import AnnotationList from "../annotations";

function AnnotationOverlay(props) {
  const [show, setShow] = useState(false);

  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);
  const overlay = useSelector((state) => state.overlay);

  useEffect(() => {
    if (overlay && overlay.type === "annotation") {
      setShow(overlay.active);
    }
  }, [overlay]);

  const dispatch = useDispatch();
  const { setOverlay } = bindActionCreators(actionCreators, dispatch);

  const handleClose = () => setOverlay({ active: false, type: "annotation" });
  const handleShow = () => setShow(true);

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        name="annotations"
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {project ? project.name : "Annotations"}
            <br />
            {time && time.timeSelected ? time.timeSelected : ""}
          </Offcanvas.Title>
          <Button
            onClick={() => {
              props.setAnnotationObj(null);
              props.setShowAnnotationModal(true);
            }}
          >
            +
          </Button>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {props.annotations ? (
            <AnnotationList
              annotations={props.annotations}
              setAnnotationObj={props.setAnnotationObj}
              setShowAnnotationModal={props.setShowAnnotationModal}
            />
          ) : null}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default AnnotationOverlay;
