//@ts-check
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector } from "react-redux";
import { createParseObj, updateParseObj } from "../../functions/parse";

function AnnotationModal(props) {
  const time = useSelector((state) => state.time);
  const user = useSelector((state) => state.user);

  const handleClose = () => {
    props.setAnnotationObj(null);
    props.setShow(false);
  };
  const handleShow = () => props.setShow(true);

  const cleanState = {
    type: "note",
    title: "",
    text: "",
    link: "",
    isoWeek: "",
    userId: user.id,
    userInitials: user.initials,
    userName: user.name,
    project: "",
  };

  const [annotation, setAnnotation] = useState(cleanState);

  const save = async () => {
    console.log("save");

    const data = {
      ...annotation,
    };

    try {
      if (!props.annotationObj) {
        createParseObj("Annotation", data);
      } else {
        updateParseObj("Annotation", props.annotationObj.objectId, data);
      }
      props.fetchAnnotationsData();
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (props.annotationObj) {
      let objToClean = props.annotationObj;
      delete objToClean.createdAt;
      delete objToClean.updatedAt;
      setAnnotation({ ...annotation, ...objToClean });
    } else {
      setAnnotation({ ...cleanState });
    }
  }, [props.annotationObj]);

  // useEffect(() => {
  //   console.log("annotation =>", props.annotationObj, annotation);
  // }, [props.annotationObj, user, project]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("handleChange", name, value);
    setAnnotation({ ...annotation, [name]: value });
    console.log("annotation =>", annotation);
  };

  return (
    <Modal show={props.show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>New annotation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Title</Form.Label>
            <Form.Control
              as="input"
              rows={3}
              value={annotation.title}
              name="title"
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Description</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              autoFocus
              value={annotation.text}
              name="text"
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Type</Form.Label>
            <Form.Select
              name="type"
              value={annotation.type}
              onChange={(e) => handleChange(e)}
            >
              <option value={"note"}>Note 📝</option>
              <option value={"biz-note"}>Business Review 💰</option>
              <option value={"commercial-note"}>Promo ⭐</option>
              <option value={"feature-activation"}>Feature Active 💙</option>
              <option value={"issue"}>Issue Report ⚠️</option>
              <option value={"issue-resolved"}>Issue Fixed ✅</option>
              <option value={"incident"}>Major Incident 🔴</option>
              <option value={"incident-resolved"}>Major Incident ✅</option>
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Label>Retail Week</Form.Label>
            <Form.Select
              name="isoWeek"
              value={annotation.isoWeek}
              onChange={(e) => handleChange(e)}
            >
              <option value="" disabled>
                -
              </option>
              {time.availableWeeks &&
                time.availableWeeks.map((w) => <option value={w}>{w}</option>)}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput3">
            <Form.Label>Project</Form.Label>
            <Form.Select
              name="project"
              value={annotation.project}
              onChange={(e) => handleChange(e)}
            >
              <option value="" disabled>
                -
              </option>
              {user.projects &&
                user.projects.map((p) => (
                  <option value={p.id}>{p.name}</option>
                ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
            <Form.Label>Link</Form.Label>
            <Form.Control
              as="input"
              rows={3}
              name="link"
              value={annotation.link}
              onChange={(e) => handleChange(e)}
              aria-describedby="linkSupport"
            />
            <Form.Text id="linkSupport" muted>
              Includere https://
            </Form.Text>
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={() => save()}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AnnotationModal;
