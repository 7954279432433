//@ts-check
import React, { useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import getCookie from "../../functions/cookies";

const WhatsNewModal = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setModalShow(false);

  const currentNewscookie = "w30";

  useEffect(() => {
    const newsCookie = getCookie("loop-news");
    console.log("newsCookie =>", newsCookie);
    if (!newsCookie || newsCookie !== currentNewscookie) {
      setModalShow(true);
    }
  }, []);

  return (
    <Modal
      show={modalShow}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onClick={handleClose}
    >
      <ModalHeader closeButton>
        <ModalTitle id="contained-modal-title-vcenter">
          🤟 Rockers & Triboo buddies, we have some news
        </ModalTitle>
      </ModalHeader>
      <ModalBody>
        <Alert variant="success">
          WEEK 30 - Sezione Clickup: time tracked by customer, by ticket type,
          by user
        </Alert>
        <Alert variant="success">
          WEEK 30 - Sezione Clickup: task turnover by customer, by ticket type
        </Alert>
        <Alert variant="secondary">
          WEEK 3 - Tabella riassuntiva di stato dei sistemi per prodotti e stock
        </Alert>
        {/* <Alert variant="secondary">
          WEEK 43 - Nella sezione errori inserito andamento day by day %errori e
          %errori checkout
        </Alert> */}
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={() => {
            document.cookie = "loop-news=" + currentNewscookie;
            setModalShow(false);
          }}
        >
          Got it bro
        </Button>
        <Button
          variant="secondary"
          onClick={() => {
            setModalShow(false);
          }}
        >
          Show me later
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default WhatsNewModal;
