//@ts-check
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function Systems() {
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col>
                <Title>Business KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/business/${time.timeSelected}`}
                  type="cards"
                />
                <Title>Store Status for Main Jobs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/systems/${project.id}`}
                  type="table"
                />
                <Title>Latest Job Runs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/systems/${project.id}/${time.timeSelected}`}
                  type="table"
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

export default Systems;
