import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import { parseQueryFirst } from "../functions/parse";
import { useParams } from "react-router-dom";
import { useContext } from "react";
import { POContext } from "../context/ParseObject";

function SystemEvent() {
  const time = useSelector((state) => state.time);

  const [PO, dispatch] = useContext(POContext);

  const routeParams = useParams();

  console.log("PO =>", PO);
  let fetched = false;
  //   if (!PO.team && routeParams.eventId && !fetched) {
  //     const query = {
  //       where: {
  //         name: { $eq: routeParams.eventId },
  //       },
  //     };
  //     parseQueryFirst("ClickUpTeam", query).then((obj) => {
  //       console.log("obj =>", obj);
  //       dispatch({ type: "SET_CLICKUPTEAM", payload: obj });
  //       fetched = true;
  //     });
  //   }

  return (
    <Container style={containerStyle}>
      <Container>
        <Row className="justify-content-md-center">
          <Col>
            <Title>Event {routeParams.eventId}</Title>
          </Col>
          <Col md="auto">
            <TimeSelector />
          </Col>
        </Row>
        {time && time.timeSelected ? (
          <Row>
            <Col md={12}>
              <Row>
                <Col md={6}>
                  <Title>Total Capacity</Title>
                  <DataContainer
                    apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/systems/event/${routeParams.eventId}`}
                    type="table"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        ) : null}
      </Container>
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

export default SystemEvent;
