//@ts-check
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function UXPotential() {
  const time = useSelector((state) => state.time);
  const project = useSelector((state) => state.project);

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col>
                <Title>Business KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/business/${time.timeSelected}`}
                  type="cards"
                />
                <Title>UX Warnings details</Title>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/session/ux-warning/${project.id}/table/${time.timeSelected}/all`}
                  type="table"
                />
                <Title>UX Warnings details mobile</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/session/ux-warning/${project.id}/table/${time.timeSelected}/mobile`}
                  type="table"
                />
                <Title>Use of filters</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/filters/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>Use of product configurator</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/product-configuration/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>Landing Types</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/types/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>Landing Focus</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/views/${project.id}/table/${time.timeSelected}?limit=15&pick=first&sortParam=percFirst`}
                  type="table"
                />
              </Col>
              <Col>
                <Title>UX KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/ux/${time.timeSelected}`}
                  type="cards"
                />
                <Title>🔍 Contextual Analysis trends</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/context/${project.id}/graph/recent`}
                  type="graphLine"
                />
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/context/${project.id}/table/${time.timeSelected}`}
                  type="table"
                />
                <Title>Checkout Warnings details</Title>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/warning/${project.id}/table/${time.timeSelected}/all`}
                  type="table"
                />
                <Title>Checkout Warnings details mobile</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/checkout/warning/${project.id}/table/${time.timeSelected}/mobile`}
                  type="table"
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

const titles = {};
export default UXPotential;
