//@ts-check
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { ButtonCol } from "../components/buttons";
import DataContainer from "../components/data-container";
import TimeSelector from "../components/time-selector";
import { Title } from "../components/typography";
import WelcomeBack from "../components/welcome-back";

function Stock() {
  const time = useSelector((state) => state.time);
  const project = useSelector((state) => state.project);

  return (
    <Container style={containerStyle}>
      {!project ? (
        <WelcomeBack />
      ) : (
        <Container>
          <Row className="justify-content-md-center">
            <Col>
              <h3>{project.name}</h3>
            </Col>
            <Col md="auto">
              <TimeSelector />
            </Col>
          </Row>
          {time && time.timeSelected ? (
            <Row>
              <Col>
                <Title>Business KPIs</Title>
                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/business/${time.timeSelected}`}
                  type="cards"
                />
                <Row>
                  <Col>
                    <Title>OOS Items (Max 500 skus)</Title>
                  </Col>
                  <Col>
                    <ButtonCol
                      text="Stock data"
                      url={
                        project.adminURL +
                        "/stats/catalogue-stock" +
                        project.defaultStore
                      }
                      target="_blank"
                    />
                  </Col>
                </Row>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/stock-data/${project.id}/table/${time.timeSelected}?limit=500`}
                  type="table"
                />
              </Col>
              <Col>
                <Title>Store potential KPIs</Title>

                <DataContainer
                  apiUrl={`${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/cards/${project.id}/store-potential/${time.timeSelected}`}
                  type="cards"
                />
              </Col>
            </Row>
          ) : null}
        </Container>
      )}
    </Container>
  );
}

const containerStyle = {
  marginTop: "50px",
};

const titles = {};
export default Stock;
