//@ts-check
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";
import AnnotationModal from "./annotation-modal";
import AnnotationOverlay from "./annotation-overlay";
import WhatsNewModal from "./whats-new";

function ModalsAndOverlays(props) {
  // We can manage state for annotation modal here
  const [showAnnotationModal, setShowAnnotationModal] = useState(false);
  const [annotationObj, setAnnotationObj] = useState(null);
  const [annotationList, setAnnotationList] = useState(null);
  const user = useSelector((state) => state.user);
  const project = useSelector((state) => state.project);
  const time = useSelector((state) => state.time);
  const annotations = useSelector((state) => state.annotations);

  const dispatch = useDispatch();
  const { fetchAnnotations } = bindActionCreators(actionCreators, dispatch);

  const fetchAnnotationsData = async () => {
    fetchAnnotations(user);
  };
  useEffect(() => {
    console.log("annotationList =>", annotationList);
    if (!annotationList) {
      fetchAnnotationsData();
    }
    if (project) {
      setAnnotationList(
        annotations.filter(
          (annotation) =>
            annotation.project === project.id &&
            annotation.isoWeek === time.timeSelected
        )
      );
    } else {
      setAnnotationList([]);
    }
  }, [annotations, project, time]);

  return (
    <>
      <WhatsNewModal />
      <AnnotationModal
        show={showAnnotationModal}
        setShow={setShowAnnotationModal}
        annotationObj={annotationObj}
        setAnnotationObj={setAnnotationObj}
        fetchAnnotationsData={fetchAnnotationsData}
      />
      <AnnotationOverlay
        setAnnotationObj={setAnnotationObj}
        setShowAnnotationModal={setShowAnnotationModal}
        annotations={annotationList}
      />
    </>
  );
}

export default ModalsAndOverlays;
