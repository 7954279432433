//@ts-check
import React, { useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../state";

function TimeSelector() {
  const dispatch = useDispatch();
  const { setTime } = bindActionCreators(actionCreators, dispatch);
  const time = useSelector((state) => state.time);

  useEffect(() => {
    const fetchTime = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_DATALAYER_SERVER_URL}/api/time`
      );
      // console.log(response)
      const time = await response.json();
      // console.log('time =>', time)
      setTime({ ...time, timeSelected: time.current });
    };

    if (!time.timeSelected) {
      fetchTime();
    }
  });

  return (
    <Dropdown className="pull-right">
      <Dropdown.Toggle id="dropdown-button-dark-example1" variant="secondary">
        {!time ? "Fetching" : time.timeSelected}
      </Dropdown.Toggle>
      {time ? (
        <Dropdown.Menu variant="dark">
          {time.availableWeeks &&
            time.availableWeeks.map((w) => (
              <Dropdown.Item
                key={w}
                onClick={() => setTime({ ...time, timeSelected: w })}
              >
                {w}
              </Dropdown.Item>
            ))}
        </Dropdown.Menu>
      ) : null}
    </Dropdown>
  );
}

export default TimeSelector;
