//@ts-check
import React from "react";
import Alert from "react-bootstrap/Alert";
import Container from "react-bootstrap/Container";

import { v4 as uuid } from "uuid";

function AnnotationList(props) {
  const iconForType = (type) => {
    switch (type) {
      case "note":
        return "📝 Note: ";
      case "commercial-note":
        return "⭐ Promo: ";
      case "feature-activation":
        return "💙 Feature: ";
      case "issue":
        return "⚠️";
      case "incident":
        return "🔴 Incident: ";
      case "issue-resolved":
        return "✅ Issue Resolved: ";
      case "incident-resolved":
        return "✅ Incident Resolved: ";
      case "biz-note":
        return "💰 Review: ";
      default:
        return "📝";
    }
  };

  const variantForType = (type) => {
    switch (type) {
      case "note":
        return "secondary";
      case "biz-note":
      case "commercial-note":
        return "info";
      case "feature-activation":
        return "primary";
      case "issue":
        return "warning";
      case "incident":
        return "danger";
      case "issue-resolved":
        return "success";
      case "incident-resolved":
        return "success";
      default:
        return "secondary";
    }
  };
  return (
    <Container style={containerStyle} key={`ac-${uuid}`}>
      {props.annotations.map((annotation) => (
        <Alert
          key={`al-${annotation.objectId}`}
          variant={variantForType(annotation.type)}
          onClick={() => {
            if (props.setShowAnnotationModal && props.setAnnotationObj) {
              props.setAnnotationObj(annotation);
              props.setShowAnnotationModal(true);
            }
          }}
        >
          <strong>
            {iconForType(annotation.type)} {annotation.title}
          </strong>
          <br />
          {annotation.text}
          {annotation.link ? (
            <span
              style={annotationLink}
              onClick={() => window.open(annotation.link, "_blank")}
            >
              🔗
            </span>
          ) : null}
        </Alert>
      ))}
    </Container>
  );
}
const containerStyle = {
  marginTop: "20px",
};

const annotationLink = {
  position: "absolute",
  right: "10px",
  top: "10px",
  fontSize: "1.2rem",
  cursor: "pointer",
};

export default AnnotationList;
